/* eslint-disable */
import React from 'react'
import Helmet from '../../../components/Helmet'

import { graphql } from 'gatsby'

import friendsForever from '../../../images/heroes/friends-forever.jpg'
import friendsForeverMobile from '../../../images/heroes/friends-forever-mobile.jpg'
import walkingOnBridge from '../../../images/people/walking-on-bridge.jpg'
import walkingOnBridgeMobile from '../../../images/people/walking-on-bridge-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../../images/brand-logos/aetna.svg'
import cigna from '../../../images/brand-logos/cigna.svg'
import regence from '../../../images/brand-logos/regence.svg'
import united from '../../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../../images/brand-logos/wellcare.svg'
import mutual from '../../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../../components/Header'
import footer from '../../../components/Footer'
import headerData from '../../../data/headerData.js'
import isCallCenterOpen from '../../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-dsnplp']
  const disclaimerList = brandyList
    .map((disclaimerId) =>
      data.allBrandyDisclaimer.edges.find(
        ({ node }) => node.brandy_id === disclaimerId
      )
    )
    .filter((node) => node)

  const footerDisclaimers = disclaimerList.map(({ node }) => {
    return {
      brandy: {
        text: node.text,
        symbol: node.symbol,
        key: node.brandy_id,
      },
    }
  })

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Explore Medicare D-SNPs | Healthcareplans.com | 833-316-3576| Medicare Insurance | 833-316-3667',
          description:
            'Dual Eligible Special Needs Plans (D-SNPs) provide additional coverage for those with both Medicaid and Medicare. Explore your D-SNP options today. 833-316-3667',
          canonical: 'https://www.healthcareplans.com/plans/snp/dsnp',
          robots: 'follow,index',
        },
        path: '/plans/snp/dsnp',
        promoCode: '169413',
        customBreadCrumbs: [
          {
            shortName: 'Home',
            path: '/',
          },
          {
            shortName: 'Plans',
            path: '/plans',
          },
        ],
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={friendsForever}
                  alt="a trio of elderly women go walking on park trail and hug, smiling"
                />
              }
              mobileImage={
                <img
                  src={friendsForeverMobile}
                  alt="a trio of elderly women go walking on park trail and hug, smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medicare Dual Eligible Special Needs Plans (D-SNPs){' '}
                  </Typography>

                  <Typography variant="body">
                    Dual Eligible Special Needs Plans (D-SNPs) are unique plans
                    available to those eligible for both Medicare and Medicaid.
                    D-SNPs provide an affordable Medicare option for those who
                    qualify, with low or $0 monthly plan premiums. See if you
                    qualify for a special needs Medicare plan.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className="margin-x-auto-small"
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare D-SNP Plan"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos bg-lightgray"
          mainContent={
            <Typography variant="h2">
              We partner with the top insurance providers{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">D-SNP benefits and features</Typography>

              <Typography variant="body">
                A Dual Eligible Special Needs Plan acts as a kind of Medicare
                Advantage plan for people who qualify for both Medicare (either
                by age or special circumstance) and Medicaid (by income). As
                such, it provides all the benefits of Original Medicare (Parts A
                & B), including hospital and hospice care and medical insurance
                coverage. In addition, D-SNPs may provide benefits especially
                helpful for those enrolled in both Medicare and Medicaid, such
                as the following:
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/dollar.svg"
                  alt="dollar icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Monthly Stipend</Typography>
                <Typography variant="body">
                  Some plans include a monthly allowance to help purchase
                  healthy groceries, over-the-counter medication, or home
                  utilities.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/prescription-drugs.svg"
                  alt="prescription drugs icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Prescription Drug Coverage</Typography>
                <Typography variant="body">
                  Many D-SNPs provide coverage for prescribed medications.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/dental.svg"
                  alt="dental care icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Dental Coverage</Typography>
                <Typography variant="body">
                  A D-SNP often includes coverage for preventative dental care,
                  such as checkups and cleanings.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/car.svg"
                  alt="car icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Transportation</Typography>
                <Typography variant="body">
                  A D-SNP may offer transportation costs for doctor visits and
                  other essential travel, such as trips to the pharmacy.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          alignMainContent="center"
          className="padding-bottom-0"
          mainContent={
            <>
              <Typography variant="h2">Who is eligible for a D-SNP?</Typography>
              <Typography variant="body">
                To qualify for a D-SNP, you must be eligible for Medicare by age
                or special circumstance, as well as entitled to state assistance
                through a Medicaid Plan.
              </Typography>
            </>
          }
        ></VariableContent>
        <SplitContent
          backgroundColor="light"
          alignImageToBottom={false}
          image={
            <img
              src={walkingOnBridge}
              alt="an elderly woman walks on bridge with water bottle"
            />
          }
          mobileImage={
            <img
              src={walkingOnBridgeMobile}
              alt="an elderly woman walks on bridge with water bottle"
            />
          }
          mainContent={
            <>
              <Typography variant="h4">Medicare eligibility:</Typography>
              <List>
                <ListItem className="margin-bottom-0">
                  <strong>By age:</strong> If you are a United States citizen
                  who has been living in the U.S. for the past 5 years and 65
                  years of age (or nearing your 65th birthday), you are eligible
                  for Original Medicare (Parts A & B).
                </ListItem>
                <ListItem>
                  <strong>By special circumstance:</strong> Some disabilities
                  and conditions may qualify you for Medicare coverage before
                  age 65:
                  <List>
                    <ListItem className="margin-bottom-0">
                      If you are disabled and have received Social Security
                      benefits for at least 24 consecutive months
                    </ListItem>
                    <ListItem className="margin-bottom-0">
                      If you suffer from kidney failure, also known as End-Stage
                      Renal Disease (ESRD), and have had a kidney transplant or
                      been on dialysis for 3 months
                    </ListItem>
                    <ListItem>
                      If you have amyotrophic lateral sclerosis (ALS, also known
                      as Lou Gehrig’s disease)
                    </ListItem>
                  </List>
                </ListItem>
              </List>
              <Typography variant="h4">Medicaid eligibility:</Typography>
              <List>
                <ListItem className="margin-bottom-0">Full Medicaid</ListItem>
                <ListItem className="margin-bottom-0">
                  Qualified Medicare Beneficiary (QMB) without other Medicaid
                </ListItem>
                <ListItem className="margin-bottom-0">QMB Plus</ListItem>
                <ListItem className="margin-bottom-0">
                  Specified Low-income Medicare Beneficiary without other
                  Medicaid (SLMB)
                </ListItem>
                <ListItem className="margin-bottom-0">SLMB Plus</ListItem>
                <ListItem className="margin-bottom-0">
                  Qualifying Individual (QI)
                </ListItem>
                <ListItem>
                  Qualified Disabled and Working Individual (QDWI)
                </ListItem>
              </List>
            </>
          }
        />

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="dark"
          mainContent={
            <>
              <Typography variant="h2">
                Find a D-SNP in your area today
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">
                  Find a Medicare D-SNP online
                </Typography>
                <Typography variant="body">
                  Use our Medicare plan finder tool to compare plans in your
                  location—and enroll online during a qualifying enrollment
                  period.
                </Typography>
                <div className="avail-form margin-left-0">
                  <AvailabilityForm
                    placeholderText="Zip Code"
                    buttonText="Find a Medicare D-SNP Plan"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                  />
                </div>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">Give us a call</Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className=""
                  requestId={requestId}
                >
                  Call Now {rotatedNumber} | TTY 711
                </LinkButton>
                <Typography variant="body">
                  Our licensed agents are available Monday through Friday from
                  8:30 am to 5:00 pm MT.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Dual Eligible Special Needs Plans
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'How do I enroll in a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        To enroll in a Dual Eligible Special Needs Plan (D-SNP)
                        you must be eligible for Medicare and entitled to state
                        assistance through Medicaid. Medicare is generally
                        available to those age 65 and older in the United
                        States; you may also be qualified for Medicare before
                        age 65 as the result of certain conditions:
                      </Typography>
                      <List>
                        <ListItem>Some disabilities</ListItem>
                        <ListItem>
                          End-stage renal disease (permanent kidney failure
                          requiring dialysis or transplant)
                        </ListItem>
                        <ListItem>
                          Amyotrophic lateral sclerosis (ALS, also known as Lou
                          Gehrig’s disease)
                        </ListItem>
                      </List>
                      <Typography>
                        To enroll in a Medicare D-SNP, use our plan comparison
                        tool to find the plan that works best for you and enroll
                        online during a qualifying enrollment period.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'When do I enroll in a D-SNP?',
                  content: (
                    <Typography>
                      If you are qualified for a Dual Eligible Special Needs
                      Plan (D-SNP), enroll during the following qualified{' '}
                      <a href="/resources/medicare-enrollment-101/">
                        Medicare Enrollment Periods
                      </a>
                      :
                      <br />
                      <br />
                      <List>
                        <ListItem>
                          <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                            Annual Enrollment Period
                          </a>{' '}
                          – October 15-December 7 each year, available to anyone
                          Medicare-eligible. During this period you can enroll
                          in, drop, or switch Medicare D-SNPs.
                        </ListItem>
                        <ListItem>
                          <a href="/resources/special-enrollment-period-chart/">
                            Special Enrollment Periods
                          </a>{' '}
                          – Special Enrollment Periods for Dual Eligible Special
                          Needs Plans differ from those associated with other
                          plan changes. Since 2019, those who qualify for a
                          D-SNP can use an SEP to change their D-SNP once every
                          three months for the first nine months of the year:
                          <br />
                          <br />
                          <ListItem>January 1-March 31</ListItem>
                          <ListItem>April 1-June 30</ListItem>
                          <ListItem>July 1-September 30</ListItem>
                        </ListItem>
                      </List>
                    </Typography>
                  ),
                },
                {
                  title: 'What are the costs to me for a D-SNP?',
                  content: (
                    <Typography>
                      The out-of-pocket cost for a Dual Eligible Special Needs
                      Plan (D-SNP) depends upon the state and the provider, but
                      most plans feature $0 copays, deductibles, and premiums.
                      If you are entitled to full Medicaid benefits from your
                      state, all or most of your out-of-pocket costs should be
                      covered by the plan.
                    </Typography>
                  ),
                },
                {
                  title: 'What’s the difference between a D-SNP and a C-SNP?',
                  content: (
                    <Typography>
                      While they are both Medicare special needs plans, D-SNPs
                      and C-SNPs are for different groups of people. While
                      D-SNPs are available to those who qualify for both
                      Medicare and Medicaid, C-SNPs (Chronic Condition Special
                      Needs Plans) are only for those who have either a single
                      chronic condition or a related group of chronic conditions
                      depending on the specific plan.
                    </Typography>
                  ),
                },
                {
                  title:
                    'Do I need to pick a primary care physician (PCP) with a D-SNP?',
                  content: (
                    <>
                      <Typography>
                        You will need to choose a primary care physician (PCP)
                        when enrolled in a D-SNP. If you have a preferred
                        doctor, you can use our plan comparison tool to search
                        by physician to find a plan that your preferred doctor
                        accepts.
                      </Typography>
                      <Typography>
                        While you are required to have a PCP with your D-SNP,
                        D-SNPs do not require that you get a referral from the
                        PCP to see a specialist.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Can I choose a separate Part D plan with my Medicare Advantage D-SNP?',
                  content: (
                    <>
                      <Typography>
                        All Medicare special needs plans are required to provide
                        prescription drug coverage (Part D) so you will not need
                        a separate Part D plan if you enroll in a D-SNP.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
